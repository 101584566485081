import { GradientBtn } from '../../../components'
import { KaryahLogo } from '../../../images'
import Navbar from './Navbar'
import ContentWrapper from '../ContentWrapper'
import MobileMenu from './MobileMenu'
import { Link } from 'react-router-dom'
interface IHeaderProps {
  handlePopupForm: any
}

const Header = (props: IHeaderProps) => {
  return (
    <ContentWrapper className="px-[8%] py-6 fixed backdrop-blur-xl z-10 w-full">
      <div className="grid grid-cols-3">
        <Link to="/">
          <img src={KaryahLogo} alt="Logo" className="col-span-1" />
        </Link>
        <div className="flex flex-row-reverse items-center gap-10 col-span-2 hidden md:flex">
          <GradientBtn
            text="Get in Touch"
            size="md"
            onClick={() =>
              props.handlePopupForm && props.handlePopupForm('contact')
            }
            type={'transparent'}
          />
          <Navbar />
        </div>
        <div className="md:hidden">
          <MobileMenu handlePopupForm={props.handlePopupForm} />
        </div>
      </div>
    </ContentWrapper>
  )
}

export default Header
