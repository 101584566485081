import { HeroBg, MainHeroBg1 } from '../../images'
import { Typography, GradientBtn } from '../../components'
interface IMainHeroProps {
  handlePopupForm: any
}

const MainHero = (props: IMainHeroProps) => {
  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${MainHeroBg1})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className="grid md:grid-cols-2 grid-cols-1 md:place-items-center pt-28 px-[10%] md:py-9">
        <div className="max-sm:w-full">
          <Typography
            size={'xl'}
            type={'light'}
            className="leading-snug tracking-[12px] text-left m-2 hidden lg:block"
          >
            AUTOMATED
          </Typography>
          <Typography
            size={'xs'}
            type={'light'}
            className="leading-snug tracking-[8px] text-left m-2 lg:hidden"
          >
            AUTOMATED
          </Typography>
          <Typography
            size={'8xl'}
            type={'bold'}
            className="text-left text-pretty lg:block hidden"
          >
            Workflow
          </Typography>
          <Typography
            size={'8xl'}
            type={'bold'}
            className="text-left text-pretty lg:block hidden"
          >
            Management
          </Typography>
          <Typography
            size={'8xl'}
            type={'bold'}
            className="text-left text-pretty lg:block hidden"
          >
            SaaS
          </Typography>
          <Typography
            size={'5xl'}
            type={'bold'}
            className="text-left text-pretty lg:hidden"
          >
            Workflow
          </Typography>
          <Typography
            size={'5xl'}
            type={'bold'}
            className="text-left text-pretty lg:hidden"
          >
            Management
          </Typography>
          <Typography
            size={'5xl'}
            type={'bold'}
            className="text-left text-pretty lg:hidden"
          >
            SaaS
          </Typography>
          <GradientBtn
            text="Get in Touch"
            size="md"
            onClick={() =>
              props.handlePopupForm && props.handlePopupForm('contact')
            }
            className="w-fit my-10"
            type={'glow'}
          />
        </div>
        <img src={HeroBg} alt="bg1" className="max-sm:hidden" />
      </div>
    </div>
  )
}

export default MainHero
