import Marquee from 'react-fast-marquee'
import { Typography } from '../../../components'
import { KaryahClients } from '../../../constants/AppConstants'

const OurClients = () => {
    return (<>
      <Typography
        size={'5xl'}
        type={'light'}
        className=" text-center max-sm:px-8 hidden md:block"
        isGradientText
      >
        Businesses That Trust Us
      </Typography>
      <Typography
        size={'4xl'}
        type={'light'}
        className=" text-center max-sm:px-8 md:hidden"
        isGradientText
      >
        Businesses That Trust Us
      </Typography>
    {KaryahClients?.length > 0 && (
        <Marquee autoFill={true} speed={35}>
          <div className={`flex my-10`} key={'div'}>
            {KaryahClients?.map((item: any) => (
                <img
                  src={item.image_url}
                  alt={item.title}
                  className='p-12'
                  key={item.title}
                />
            ))}
          </div>
        </Marquee>
      )}
      </>)
}

export default OurClients