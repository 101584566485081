import ContentWrapper from '../Common/ContentWrapper'
import { Typography } from '../../components'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TermsAndConditions = () => {
  const { state } = useLocation()
  const { targetId } = state || {}

  useEffect(() => {
    const el = document.getElementById(targetId)
    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        250
      )
    } else {
      setTimeout(() => {
        if (typeof window != 'undefined') {
          window.scrollTo({ top: (0, 0), behavior: 'smooth', alignToTop: true })
        }
      }, 200)
    }
  }, [targetId])
    return (
        <ContentWrapper className='pt-40 px-[10%] md:py-18 '>
            <Typography size={'2xl'} type={'bold'} className='pb-6'>
              {'Terms & Conditions'}
            </Typography>
            <ContentWrapper className='pt-8 px-[8%] md:py-9 bg-[#2b344040]/25'>
            <Typography
              size={'sm'}
              type={'light'}
              className={`leading-normal line-clamp-12 max-h-[400px] md:max-h-[400px] overflow-scroll `}
            >
              Welcome to Karyamsha Labs Pvt Ltd ('Company,' 'we,' 'our,' 'us'). By using our software products and/or services ('Products and/or Services'), you agree to the following terms.
              
              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Acceptance of Terms</Typography>
              By accessing or using our Products and/or Services, you agree to these Terms and our Privacy Policy. If you do not agree, you must discontinue using our Products and/or Services.
              
              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Modifications</Typography>
              We may update these Terms from time to time. We will notify you of any changes by posting the revised Terms on our website. Continued use of our Services signifies acceptance of the updated Terms.
              
              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >User Accounts</Typography>
              To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.
              
              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >License</Typography>
              We grant you a limited, non-exclusive, non-transferable license to use our Products and/or Services for personal or internal business purposes. You may not copy, modify, or distribute our Products and/or Services without permission.
              
              <br/><br/><br/>

              <Typography size={'md'} type={'regular'} >Codebase Handover</Typography>
              For custom web application development, upon full payment:
              <ol style={{
                  listStyleType: 'disc',
                  paddingLeft: 18,
                  display: 'inline-block',
                }}>
                <li><strong>Delivery: </strong>We will provide you with the final codebase, including source code and documentation.</li>
                <li><strong>Rights: </strong>You own the rights to use the codebase. We retain rights to any pre-existing tools or libraries used.
                </li>
                <li><strong>Support: </strong>Post-handover support is not included unless specified in a separate agreement.</li>
                <li><strong>Modifications: </strong>Changes to the codebase after handover will be charged separately. We will provide a No Objection Certificate (NOC) upon full payment and successful handover.</li>
              </ol>

              <br/><br/><br/>

              <Typography size={'md'} type={'regular'} >Copyright Policy</Typography>
              <ol style={{
                  listStyleType: 'disc',
                  paddingLeft: 18,
                  display: 'inline-block',
                }}>
                <li><strong>Ownership: </strong>We retain copyright of the web applications, including codebase, design, and creatives, for 10 years from delivery.
                </li>
                <li><strong>Usage Rights: </strong>You may use the web application as intended. Any modifications or distribution of the application require our approval.
                </li>
              </ol>

              <br/><br/><br/>
                
              <Typography size={'md'} type={'regular'} >Intellectual Property</Typography>
              <ol style={{
                  listStyleType: 'disc',
                  paddingLeft: 18,
                  display: 'inline-block',
                }}>
                <li><strong>Ownership: </strong>All intellectual property rights, including but not limited to codebase, design, and creatives, associated with our Services are owned by us or our licensors.
                </li>
                <li><strong>Custom Work: </strong>For custom-developed web apps, you hold copyright for the delivered codebase, design, and creatives. However, we retain rights to any pre-existing tools, libraries, or design elements used.
                </li>
              </ol>

              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Exclusion of Warranties</Typography>
              No right or provision of this terms shall be deemed waived by our inability to assert or enforce said right or provision. Any provision in this terms that is determined to be illegal or unenforceable will be amended to the extent necessary to make the modified agreement lawful and enforceable under applicable laws, without impairing the remaining elements of this terms.

              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Limitation of Liability</Typography>
              We are not liable for any indirect, incidental, or consequential damages arising from your use of our Services. Our liability is limited to the amount paid for the Services in the past six months.

              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Termination of Use</Typography>
              We reserve the right to discontinue or restrict access to its website, materials, products and/or services, or user content at any time and for any reason. If you breach the terms, we may suspend or terminate your account without prior notice, preventing future access. We are not liable for any consequences resulting from such actions. Your confidentiality obligations continue after your account is terminated. You must cease using any confidential information upon request and return or destroy it.

              <br/><br/><br/>
              
              <Typography size={'md'} type={'regular'} >Indemnification</Typography>
              You agree to indemnify us against any claims arising from your use of our Products and/or Services or violation of these Terms.

              <br/><br/><br/>

              <Typography size={'md'} type={'regular'} >Governing Law</Typography>
              These Terms are governed by the laws of Karnataka,India. Disputes will be resolved in the courts of Bangalore, Karnataka, India.

              <br/><br/><br/>
              <Typography size={'md'} type={'regular'} >Contact Us</Typography>
              For any questions, contact us at:
              <br/><br/>Karyamsha Labs Pvt Ltd
              <br/>D60, New Dawn Villas, Rayasandra main road, gattahalli, 
              <br/>Bangalore, Karnataka - 560099
              <br/><br/>Email: admin@karyah.app

              <br/><br/><br/>
              <Typography size={'md'} type={'regular'} >Entire Agreement</Typography>
              These Terms constitute the entire agreement between you and us regarding our Products and/or Services.
            </Typography>
            </ContentWrapper>
        </ContentWrapper>
    )
  }
  
export default TermsAndConditions
  