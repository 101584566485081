import { post_contact_form } from '../../../actions/send_emails'
import { Typography, GradientBtn } from '../../../components'
import { KaryahLogo } from '../../../images'
import ContentWrapper from '../ContentWrapper'
import Copyright from './Copyright'
import { useState } from 'react'

const Footer = () => {
  const [recEmail, setRecEmail] = useState('')
  const [isFormSent, setisFormSent] = useState(false)

  const handleInputChange = (e: any) => {
    setRecEmail(e.target.value)
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const res = await post_contact_form({
      name: '',
      email: recEmail,
      mobile: '',
      industry: '',
    })
    // const res=true
    if (res) {
      setRecEmail('')
      setisFormSent(true)
      setTimeout(() => {
        setisFormSent(false)
      }, 2000)
    } else {
      setRecEmail('')
      setisFormSent(true)
      setTimeout(() => {
        setisFormSent(false)
      }, 2000)
    }
  }

  return (
    <ContentWrapper className="px-[8%] pt-12 pb-3 bg-black">
      <div className="flex md:justify-between md:items-start md:gap-20 max-sm:justify-center">
        <img src={KaryahLogo} alt="logo" />
        {/* <div className=" hidden lg:block">
          <Typography
            size={'lg'}
            type={'regular'}
            className="text-neutral-400 pb-5"
          >
            Title Page 01
          </Typography>
          <div className="space-y-4">
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            size={'lg'}
            type={'regular'}
            className="text-neutral-400 pb-5"
          >
            Title Page 01
          </Typography>
          <div className="space-y-4">
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            size={'lg'}
            type={'regular'}
            className="text-neutral-400 pb-5"
          >
            Title Page 01
          </Typography>
          <div className="space-y-4">
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
            <Typography
              size={'sm'}
              type={'regular'}
              className="text-neutral-400"
            >
              Page 01
            </Typography>
          </div>
        </div> */}
      </div>
      <form
        className="grid md:grid-cols-2 grid-cols-1 place-items-center mt-20 gap-5 p-10 bg-[#2b344040]/25 rounded-md"
        onSubmit={handleSubmit}
      >
        <div className="w-full max-sm:text-center">
          <Typography size={'lg'} type={'semi'} className="pb-1">
            GET IN TOUCH
          </Typography>
          <Typography size={'sm'} type={'light'} className="max-sm:p-2">
            Sign up with your email address to receive news and updates
          </Typography>
        </div>
        <div className="w-full flex justify-between items-center border-gradient rounded-full p-px">
          <div className="bg-black rounded-full flex w-full">
            <input
              className="border-none bg-transparent w-[80%] p-2 pl-8 outline-none"
              placeholder="Enter your email"
              value={recEmail}
              onChange={handleInputChange}
              required
            />
            {!isFormSent && (
              <>
                <div className="hidden lg:block">
                  <GradientBtn
                    text="Submit"
                    size="lg"
                    isSubmitButton={true}
                    isButtonDisable={false}
                    type={'fill'}
                    className="m-1"
                  />
                </div>
                <div className="hidden md:block lg:hidden">
                  <GradientBtn
                    text="Submit"
                    size="md"
                    isSubmitButton={true}
                    isButtonDisable={false}
                    type={'fill'}
                    className="m-1"
                  />
                </div>
              </>
            )}
            {isFormSent && (
              <>
                <div className="hidden lg:block">
                  <GradientBtn
                    text="Submitted!"
                    size="lg"
                    isSubmitButton={false}
                    isButtonDisable={true}
                    type={'transparent'}
                    className="m-1"
                  />
                </div>
                <div className="hidden md:block lg:hidden">
                  <GradientBtn
                    text="Submitted!"
                    size="md"
                    isSubmitButton={false}
                    isButtonDisable={true}
                    type={'transparent'}
                    className="m-1"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="block md:hidden">
          {!isFormSent ? (
            <GradientBtn
              text="Submit"
              size="lg"
              type={'fill'}
              isSubmitButton={true}
              isButtonDisable={false}
            />
          ) : (
            <GradientBtn
              text="Submited!"
              size="lg"
              type={'transparent'}
              isSubmitButton={false}
              isButtonDisable={true}
            />
          )}
        </div>
      </form>
      <Copyright />
    </ContentWrapper>
  )
}

export default Footer
