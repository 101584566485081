/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import { ArrowDown, ArrowUp, BulletGreenTick } from '../../images'
import Typography from '../Typography'
import Divider from '../Divider'
import GradientBtn from '../Button/GradientBtn'

interface IAccordion {
  img: string
  desc: any
  desc2?: any
  usecases?: any
  isOpenIcon?: boolean
  isCardOpen?: boolean
  showBtn?: boolean
  isGradientDesc?: boolean
  showDivider?: boolean
  isCenterText?: boolean
  usecasesTitle: string
  footerTitle?: string
  footerDesc?: string[]
}

const Accordion = (props: IAccordion) => {
  const { img, desc, desc2, usecases, usecasesTitle, footerTitle, footerDesc, isOpenIcon = true, isCardOpen= false, isGradientDesc=false, showBtn=true, showDivider=true, isCenterText=false } = props
  const [isOpen, setIsOpen] = useState<boolean>(isCardOpen)

  return (
    <div className={`row-span-1 flex flex-col rounded-2xl p-[1px] ${isOpen ? 'h-full border-gradient-diagonal' : 'w-full h-30 border-gradient' }`}>
    <div className={`p-6 backgroundGradient rounded-2xl h-full`}>
    {isOpenIcon && (
        <>
          {!isOpen && (
            <div className="flex justify-end">
              <img
                src={ArrowDown}
                alt="arrow-down"
                onClick={() => setIsOpen(true)}
              />
            </div>
          )}
          {isOpen && (
            <div className="flex justify-end">
              <img
                className="items-end"
                src={ArrowUp}
                alt="arrow-up"
                onClick={() => setIsOpen(false)}
              />
            </div>
          )}
        </>
      )}
      <img src={img} alt={img} className='w-full p-4'  onClick={() => setIsOpen(!isOpen)} />
      <div className={`${isOpen ? 'h-full block' : 'h-0 hidden'} transition-all ease-in-out duration-500`}>
          {isGradientDesc ? <Typography size={'3xl'} type={'semi'} className={`pt-8 row-span-1 m-auto ${isCenterText? 'text-center': ''} `} isGradientText>
            {desc}
          </Typography> : <Typography size={'sm'} type={'light'} className="py-8 row-span-1">
            {desc}
          </Typography>}
          <Typography size={'sm'} type={'light'} className={`py-4 row-span-1 ${isCenterText? 'text-center': ''}`}>
            {desc2}
          </Typography>
          {showDivider && <Divider width='w-full' />}
          <div className={`row-span-2 ${isCenterText? 'grid place-items-center': ''}`}>
            <Typography size={'xs'} type={'regular'} className={`py-4`}>
              {usecasesTitle}
            </Typography>
            <div className="space-y-1 py-4">
              {usecases.map((item: any) => {
                return (
                  <div className='flex justify-start' key={item.title}>
                    <img src={BulletGreenTick} alt="" className='pr-2' />
                    <Typography size={'xs'} type={'regular'} key={item} className='py-2'>
                    {item}
                  </Typography>
                  </div>
                )
              })}
            </div>
          </div>
          {footerTitle && <><Typography size={'sm'} type={'semi'} className="row-span-1 text-center pt-8">
              {footerTitle}
          </Typography>
          <Typography size={'sm'} type={'light'} className="row-span-1 text-center pb-8">
              {footerDesc}
          </Typography></>}
          {showBtn && <div className={`row-span-1 p-4 grid place-items-center`}>
            <GradientBtn
              text="Coming Soon"
              size="md"
              onClick={function (): void {
                throw new Error('Function not implemented.')
              }}
              type={'transparent'}
            />
          </div>}
      </div>
    </div>
  </div>)
}
export default Accordion
