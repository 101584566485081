// import { Typography } from '../../components'
// import Divider from '../../components/Divider'
import { MainHeroBg2, MainHeroBg3 } from '../../images'
import ContentWrapper from '../Common/ContentWrapper'
import DevDuo from './DevDuo'
import Products from './Prodcts'
import MainHero from './MainHero'
import Dashboard from './Dashboard'
import KeyFeatures from './KeyFeatures'
import OurClients from '../Common/OurClients'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// import { ScrollParallax } from 'react-just-parallax'
interface IHomePageProps {
  handlePopupForm: any
  setToast: any
}

const HomePage = (props: IHomePageProps) => {
  const { state } = useLocation()
  const { targetId } = state || {}

  useEffect(() => {
    const el = document.getElementById(targetId)
    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        250
      )
    } else {
      setTimeout(() => {
        if (typeof window != 'undefined') {
          window.scrollTo({ top: (0, 0), behavior: 'smooth', alignToTop: true })
        }
      }, 200)
    }
  }, [targetId])

  return (
    <>
      <ContentWrapper className="">
        <MainHero handlePopupForm={props.handlePopupForm} />
        <Dashboard />
        {/* <Typography
          size={'md'}
          type={'light'}
          className="leading-snug tracking-[12px] text-center"
        >
          COMING SOON
        </Typography>
        <Divider width="md:w-[16%] w-[50%] mb-8" /> */}
      </ContentWrapper>
      <KeyFeatures />
      <ContentWrapper className="max-lg:px-[10%] px-[2%] md:py-9  bg-gradient-2">
        <Products />
      </ContentWrapper>
      <ContentWrapper
        className="px-[8%] py-9 max-sm:mt-14 text-white bg-gradient-1 justify-center "
        backgroundImage={MainHeroBg2}
      >
        <DevDuo />
      </ContentWrapper>
      <ContentWrapper
        className="pt-20 pb-12  bg-gradient-1"
        backgroundImage={MainHeroBg3}
      >
        <OurClients />
      </ContentWrapper>
    </>
  )
}

export default HomePage
