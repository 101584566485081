import { Typography } from '../../../components'
import { navbarList } from '../../../constants/AppConstants'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className="flex items-center gap-8">
      {navbarList.map((item) => (
        <Link to={item.link} className='pr-10' key={item.name}>
        <Typography key={item.name} size={'sm'} type={'light'}>
          {item.name}
        </Typography>
        </Link>
      ))}
    </div>
  )
}

export default Navbar
