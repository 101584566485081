import OurClients from "../Common/OurClients"
import AboutCards from "./AboutCards"
import AboutHero from "./AboutHero"
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const About = () => {
    const { state } = useLocation()
  const { targetId } = state || {}

  useEffect(() => {
    const el = document.getElementById(targetId)
    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        250
      )
    } else {
      setTimeout(() => {
        if (typeof window != 'undefined') {
          window.scrollTo({ top: (0, 0), behavior: 'smooth', alignToTop: true })
        }
      }, 200)
    }
  }, [targetId])
  
    return (<>
        <AboutHero />
        <AboutCards />
        <OurClients />
    </>)
}

export default About