import Header from './Header'
import Footer from './Footer'
import PopupForm from '../Forms'

interface IMainLayout {
  children: React.ReactNode
  handlePopupForm: any
  whichPopupForm: string
  showPopupForm: boolean
  setShowPopupForm: any
  toast: any
  setToast: any
}

const MainLayout = (props: IMainLayout) => {
  const {
    children,
    handlePopupForm,
    whichPopupForm,
    showPopupForm,
    setShowPopupForm,
    toast,
    setToast,
  } = props

  return (
    <div className="h-full bg-black">
      <Header handlePopupForm={handlePopupForm} />
      <PopupForm
        formname={whichPopupForm}
        isOpen={showPopupForm}
        setIsOpen={setShowPopupForm}
        setToast={setToast}
      />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default MainLayout
