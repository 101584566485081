"use client";
import { useScroll, useTransform } from "framer-motion"
import { useRef } from "react"
import { motion } from "framer-motion"
import { cn } from "../../../utils/cn"

export const ParallaxScroll = ({
  cards,
  className
}: {
    cards: any;
    className?: any;
}) => {
  
  const gridRef = useRef<any>(null);
  const { scrollYProgress } = useScroll();

  const translateOdd = useTransform(scrollYProgress, [0, 1], [0, 140]);
  const translateEven = useTransform(scrollYProgress, [0, 1], [0, -140]);

  return (
    <div
      className={cn("overflow-y-auto items-start py-10", className)}
      ref={gridRef}
    >
      <div
        className={`grid grid-cols-6 items-start w-full py-4 gap-2 justify-center`}
        ref={gridRef}
      >
        {cards.map((el, idx) => {
            return (<>
            {el.data.map((m, k) => {
              return (<motion.div
                style={{ y: m.translate === 'even' ? translateEven : translateOdd }} // Apply the translateY motion value here
                key={k}
                className={`col-span-${m.colspan} row-span-${m.rowspan} ${m.classname}   drop-shadow-xl`}
              >
              {m.type==='image' && <img
              src={m.src}
              className="h-auto w-full rounded-lg"
              height="400"
              width="400"
              alt="thumbnail"
              /> }
              {m.type === 'child' && <m.child />}
              </motion.div>)
            })}</>)
        })}
    </div>
    </div>
  )
}