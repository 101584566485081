import {
  AksharaLogo,
  CrewwLogo,
  ModernSpaacesLogo,
  EduSpinLogo,
  KaryahDevLogo,
  KaryahSalesLogo,
  KaryahWorkLogo,
  KaryahCreateLogo,
  Prep360Logo,
  Spin1Logo,
  SpinLaunchLogo,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature10,
  Feature11,
  Feature12,
  Feature5,
  Feature6,
  Feature7,
  Feature8,
  Feature9,
  AboutSirisha,
  AboutPradeep,
} from '../images'
import { KaryahKeyFeaturesTitle } from './AppElements'

export const navbarList = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About',
    link: '/about',
  },
]

export const KaryahProducts = [
  {
    id: '1',
    img: KaryahWorkLogo,
    desc: 'For professionals and teams managing tasks & workflows',
    desc2:
      'Manage tasks and workflows to fuel team collaboration and productivity at scale.',
    usecases: [
      'Task Management',
      'Project Management',
      'Automated Workflows',
      'Build your own',
    ],
  },
  {
    id: '2',
    img: KaryahCreateLogo,
    desc: 'For creative and marketing professionals and teams',
    desc2:
      'Create and collaborate on design projects to   level up your marketing game.',
    usecases: [
      'Research and Branding',
      'Product Design',
      'Digital Assets',
      'Marketing Projects',
    ],
  },

  {
    id: '3',
    img: KaryahSalesLogo,
    desc: 'For sales professionals and customer-facing teams',
    desc2:
      'Track and manage all aspects of your sales cycle, customer data, and more in one place.',
    usecases: [
      'Vendor Management',
      'Sales Pipeline',
      'Post sales engagement',
      'Lead Management',
    ],
  },
  {
    id: '4',
    img: KaryahDevLogo,
    desc: 'For product and development professionals and teams',
    desc2:
      'Build agile workflows to drive impact across your product, design, and R&D teams.',
    usecases: [
      'Task Dashboards',
      'Web & Mobile Apps',
      'Enterprise Integrations',
      'Tailored Tools',
    ],
  },
]

export const KaryahClients = [
  {
    title: 'spin1',
    image_url: Spin1Logo,
  },
  {
    title: 'modernspaaces',
    image_url: ModernSpaacesLogo,
  },
  {
    title: 'akshara',
    image_url: AksharaLogo,
  },
  {
    title: 'eduspin',
    image_url: EduSpinLogo,
  },
  // {
  //   title: 'creww',
  //   image_url: CrewwLogo,
  // },
  {
    title: 'spinlaunch',
    image_url: SpinLaunchLogo,
  },
  {
    title: 'prep360',
    image_url: Prep360Logo,
  },
]

export const KaryahKeyFeaturesCards = [
  {
    data: [
      {
        type: '',
        child: '',
        colspan: 1,
        classname: '',
        translate: 'even',
      },
      {
        type: 'child',
        child: KaryahKeyFeaturesTitle,
        colspan: 2,
        classname: 'lg:-mt-10 -mt-24',
        translate: 'odd',
      },
      {
        type: '',
        child: '',
        colspan: 1,
        classname: '',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature9,
        colspan: 1,
        classname: 'mt-10',
        translate: 'even',
      },
      {
        type: '',
        child: '',
        colspan: 1,
        classname: '',
        translate: 'even',
      },
    ],
  },
  {
    data: [
      {
        type: 'image',
        src: Feature1,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-28',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature3,
        colspan: 1,
        rowspan: 2,
        classname: '-mt-32',
        translate: 'odd',
      },
      {
        type: '',
        child: '',
        colspan: 1,
        rowspan: 1,
        classname: '',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature7,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-32',
        translate: 'odd',
      },
      {
        type: 'image',
        src: Feature10,
        colspan: 1,
        rowspan: 1,
        classname: 'mt-4',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature11,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-64',
        translate: 'odd',
      },
    ],
  },
  {
    data: [
      {
        type: 'image',
        src: Feature2,
        colspan: 1,
        rowspan: 2,
        classname: 'lg:-mt-72 -mt-52',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature5,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-60',
        translate: 'even',
      },
      {
        type: 'image',
        src: Feature8,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-96',
        translate: 'odd',
      },
      {
        type: '',
        child: '',
        colspan: 1,
        rowspan: 1,
        classname: '',
        translate: 'odd',
      },
      {
        type: 'image',
        src: Feature12,
        colspan: 1,
        rowspan: 1,
        classname: '-mt-56',
        translate: 'odd',
      },
    ],
  },
  {
    data: [
      {
        type: 'image',
        src: Feature4,
        colspan: 1,
        classname: '-mt-36',
        translate: 'odd',
      },
      {
        type: 'image',
        src: Feature6,
        colspan: 2,
        classname: '-mt-36',
        translate: 'odd',
      },
    ],
  },
]

export const Industries = [
  'Construction',
  'Education',
  'Real Estate',
  'Retail',
  'Software',
  'Agriculture',
  'Business Services',
  'Finance',
  'Government',
  'Healthcare',
  'Insurance',
  'Law Firms & Legal Services',
  'Manufacturing',
  'Media & Internet',
]

export const KaryahFounders = [
  {
    id: '1',
    img: AboutSirisha,
    experience: '10+ Years Exp',
    expertise: 'Engineering & Delivery',
    usecases: [
      'Technical Design',
      'Web & Mobile Apps',
      'SaaS Products',
      'Project Management',
      'Micro Services',
    ],
    footerTitle: 'Co-Founded :',
    footerDesc: ['Gala (2018-2019)','Karyah(2024-Present)'],
  },
  {
    id: '2',
    img: AboutPradeep,
    experience: '14+ Years Exp',
    expertise: 'Marketing & Design',
    usecases: [
      '2D & 3D Design',
      'Product Marketing',
      'People Function',
      'Brand Management',
      'Film/TV Animation',
    ],
    footerTitle: 'Co-Founded :',
    footerDesc: ['SPIN1 (2020-Present)', 'Karyah (2024-Present)'],
  },
]
