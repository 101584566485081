import ContactForm from './ContactForm'
import { Popup } from '../../components'

interface IPopupForm {
  formname: string
  isOpen: boolean
  setIsOpen: any
  setToast: any
}
const PopupForm = (props: IPopupForm) => {
  const { formname, isOpen, setIsOpen, setToast } = props

  const getTitle = (name = formname) => {
    switch (name) {
      case 'contact':
        return 'Get In Touch'
      default:
        return 'Get In Touch'
    }
  }

  const getChild = (name = formname) => {
    switch (name) {
      case 'contact':
        return <ContactForm setIsOpen={setIsOpen} setToast={setToast} />
      default:
        return <ContactForm setIsOpen={setIsOpen} setToast={setToast} />
    }
  }
  return (
    <Popup
      title={getTitle()}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      child={getChild()}
      width={'sm:w-full sm:max-w-lg'}
    />
  )
}

export default PopupForm
