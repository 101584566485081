import { Typography, Accordion, GradientBtn, Divider } from '../../components'
import { KaryahProducts } from '../../constants/AppConstants'
import { BulletGreenTick } from '../../images'
import ContentWrapper from '../Common/ContentWrapper'

const Products = () => {
  return (
    <>
    <ContentWrapper className="py-12 md:text-center">
      <Typography
        size={'5xl'}
        type={'light'}
        isGradientText
        className='md:block hidden'
      >
        Personalized Products
      </Typography>
      <Typography
        size={'5xl'}
        type={'light'}
        isGradientText
        className='md:block hidden'
      >
        to suit your Business Needs
      </Typography>
      <Typography
        size={'4xl'}
        type={'light'}
        isGradientText
        className='md:hidden'
      >
        Personalized Products
      </Typography>
      <Typography
        size={'4xl'}
        type={'light'}
        isGradientText
        className='md:hidden'
      >
        to suit your Business Needs
      </Typography>
      </ContentWrapper>
   
    <div className="container grid grid-cols-4 place-items-center gap-8 md:pt-8 pt-18 hidden md:grid m-auto">
      {KaryahProducts.map((item) => {
        return (
          <div
            key={item.id}
            className="row-span-1 flex flex-col rounded-xl p-[1px] border-gradient-diagonal h-full m-auto hover:-translate-y-3"
          >
            <div className="p-8 backgroundGradient rounded-xl h-full grid grid-rows-8">
              <img src={item.img} alt={item.img} className='row-span-1 py-4' />
              <Typography size={'sm'} type={'light'} className="py-8 row-span-1">
                {item.desc}
              </Typography>
              <Typography size={'sm'} type={'light'} className="py-4 row-span-1">
                {item.desc2}
              </Typography>
              <Divider width='w-full' />
              <div className='row-span-2'>
                <Typography size={'xs'} type={'regular'} className='py-4'>
                  Use Cases:
                </Typography>
                <div className="space-y-1 py-4">
                
                  {item.usecases.map((item) => {
                    return (
                      <div className='flex justify-start'>
                        <img src={BulletGreenTick} alt="" className='pr-2' />
                        <Typography size={'xs'} type={'regular'} key={item} className='py-2'>
                        {item}
                      </Typography>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='row-span-1 py-4 lg:m-auto'>
                <GradientBtn
                  text="Coming Soon"
                  size="md"
                  onClick={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                  type={'transparent'}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>

    <div className="container grid grid-cols-1 place-items-center gap-8 pt-8 md:hidden block">
      {KaryahProducts.map((item) => {
        return (
          <Accordion
          img={item.img}
          desc={item.desc}
          desc2={item.desc2}
          usecases={item.usecases}
          isCardOpen={item.id === '1' ? true : false}
          usecasesTitle={'Use Cases:'}
        />
        )
      })}
    </div>

    </>
  )
}

export default Products
