import { Typography } from '../../components'
import { Heart, Pradeep, Sirisha } from '../../images'

const DevDuo = () => {
  return (
    <div className="w-full">
      <Typography
        size={'5xl'}
        type={'light'}
        className=" text-center hidden md:block"
        isGradientText
      >
        Built by the
      </Typography>
      <Typography
        size={'5xl'}
        type={'light'}
        className="flex justify-center text-center flex-wrap hidden md:flex"
        isGradientText
      >
        Design <img src={Heart} alt="heart" className="m-1 h-3 animate-heartbeat" /> Dev Duo
      </Typography>
      <Typography
        size={'4xl'}
        type={'light'}
        className=" text-center md:hidden"
        isGradientText
      >
        Built by the
      </Typography>
      <Typography
        size={'4xl'}
        type={'light'}
        className="flex justify-center text-center flex-wrap md:hidden"
        isGradientText
      >
        Design <img src={Heart} alt="heart" className="m-1 h-3 animate-pulse" /> Dev Duo
      </Typography>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-12 place-items-center md:mt-32 my-14 mx-8 md:mb-38">
        <div className="flex items-center max-sm:py-8 max-sm:w-full">
          <img
            src={Sirisha}
            alt="sirisha"
            className="w-24 h-24 bg-zinc-300 rounded-2xl object-cover"
          />
          <div className="ml-12">
            <Typography size={'2xl'} type={'regular'} isGradientText>
              Sirisha V
            </Typography>
            <Typography size={'sm'} type={'light'}>
              Co-Founder & CTO
            </Typography>
          </div>
        </div>
        <div className="flex items-center max-sm:py-8 max-sm:w-full">
          <img
            src={Pradeep}
            alt="pradeep"
            className="w-24 h-24 bg-zinc-300 rounded-2xl object-cover "
          />
          <div className="ml-12">
            <Typography size={'2xl'} type={'regular'} isGradientText>
              Pradeep C
            </Typography>
            <Typography size={'sm'} type={'light'}>
            Co-Founder & CEO
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevDuo
