import { Link } from 'react-router-dom'
import { Typography } from '../../../components'

const Copyright = () => {
  return (
    <div className="grid grid-cols-3 pt-10 place-items-center">
      <div></div>
      <Typography size={'xs'} type={'regular'} className=" text-neutral-400">
        ©Copyright {new Date().getFullYear()}, Karyamsha Labs Pvt Ltd. All
        Rights Reserved.
      </Typography>
      <Typography
        size={'xs'}
        type={'regular'}
        className="text-neutral-400 text-right"
      >
        <Link to={'/privacy-policy'}>Privacy Policy</Link> |{' '}
        <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
      </Typography>
    </div>
  )
}

export default Copyright
