'use client'
import React, { useRef } from 'react'
import { useScroll, useTransform, motion } from 'framer-motion'
export const ContainerScroll = ({ child }: { child: any }) => {
  const containerRef = useRef<any>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
  })
  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => {
      window.removeEventListener('resize', checkMobile)
    }
  }, [])

  const scaleDimensions = () => {
    return isMobile ? [0.7, 0.9] : [1.05, 1]
  }

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0])
  const scale = useTransform(scrollYProgress, [0, 1], scaleDimensions())
  const translate = useTransform(scrollYProgress, [0, 1], [0, -100])

  return (
    <div
      className="flex items-center justify-center relative  px-[10%]"
      ref={containerRef}
    >
      <div
        className="py-40 max-sm:py-12 w-full relative"
        style={{
          perspective: '1000px',
        }}
      >
        <Card
          rotate={rotate}
          translate={translate}
          scale={scale}
          child={child}
        />
      </div>
    </div>
  )
}

export const Card = ({
  rotate,
  scale,
  child,
}: {
  rotate: any
  scale: any
  translate: any
  child: any
}) => {
  return (
    <motion.div
      style={{
        rotateX: rotate, // rotate in X-axis
        scale,
        boxShadow:
          '0 0 #0000004d, 0 9px 20px #0000004a, 0 37px 37px #00000042, 0 84px 50px #00000026, 0 149px 60px #0000000a, 0 233px 65px #00000003',
      }}
      className="max-w-5xl -mt-12 mx-auto w-full shadow-2xl"
    >
      {child}
    </motion.div>
  )
}
