import { post_contact_form } from '../../actions/send_emails'
import { useState, useEffect } from 'react'
import { Industries } from '../../constants/AppConstants'
import { GradientBtn } from '../../components'

interface IContactForm {
  setIsOpen: () => void
  setToast: any
}

const ContactForm = (props: IContactForm) => {
  const { setIsOpen, setToast } = props
  const [isButtonDisable, setButtonDisabled] = useState(false)
  const [contactForm, setContactForm] = useState({
    name: {
      value: '',
      isValid: false,
      errorMsg: `Please enter your name`,
      isRequired: true,
    },
    email: {
      value: '',
      isValid: false,
      errorMsg: `Please enter your email`,
      isRequired: true,
    },
    mobile: {
      value: '',
      isValid: false,
      errorMsg: `Please enter your mobile number`,
      isRequired: false,
    },
    industry: {
      value: '',
      isValid: false,
      errorMsg: `Please select your industry`,
      isRequired: true,
    },
  })
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (isButtonDisable) {
      setTimeout(() => {
        setButtonDisabled(false)
      }, 30000)
    }
  }, [isButtonDisable])

  const handleInputChange = (field: string, value: string | number) => {
    setContactForm(() => {
      const contactCpy: any = structuredClone(contactForm)
      contactCpy[`${field}`].value = value
      return contactCpy
    })
    if (field === 'industry') {
      setSelected(value.toString())
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    const contactCpy: any = structuredClone(contactForm)
    let isRequiredFilled = true
    Object.keys(contactForm).forEach(
      (key: 'name' | 'mobile' | 'email' | 'industry') => {
        const val: string = contactForm[key].value
          ? contactForm[key].value.trim()
          : ''
        contactCpy[key].isValid = val.length > 0
        if (contactForm[key].isRequired && !contactCpy[key].isValid) {
          contactCpy[key].isValid = false
          isRequiredFilled = false
        }
      }
    )
    if (isRequiredFilled) {
      // setLoading(true)

      const payload = {
        name: contactForm.name.value,
        email: contactForm.email.value,
        mobile: contactForm.mobile.value,
        industry: contactForm.industry.value,
      }
      const res = await post_contact_form(payload)
      // setLoading(false)

      if (res?.response?.ResponseMetadata?.HTTPStatusCode === 200) {
        setToast({
          isActive: true,
          msg: 'Sent Successfully!',
          type: 'success',
        })
        setButtonDisabled(true)
        setIsOpen(false)
        setTimeout(() => {
          setToast({ isActive: false, type: '', msg: '' })
        }, 3000)
      } else {
        setToast({
          isActive: true,
          msg: 'Something went wrong please try again later!',
          type: 'error',
        })
      }
    }
  }

  const renderIndustryOptions = () => {
    const branchDom = []
    branchDom.push(
      <option value={''} key={-1} className="text-gray-400">
        Select your industry
      </option>
    )
    Industries.forEach((item: any) =>
      branchDom.push(
        <option key={item} value={item}>
          {item}
        </option>
      )
    )
    return branchDom
  }

  return (
    <div className="isolate bg-black w-full items-center mt-10 mb-5 mx-2">
      <form className="mx-auto max-w-xl" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-y-2 place-items-center w-full backgroundGradient">
          <div className="mt-4 w-full ">
            <input
              type="text"
              name="name"
              className="block w-full rounded-xl border-0 p-4 text-white shadow-xl ring-0 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 bg-gradient-3"
              placeholder={`Full Name`}
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={contactForm.name.value}
            />
          </div>
          <div className="mt-1 w-full">
            <input
              type="email"
              name="email"
              className="block w-full rounded-xl border-0 p-4 text-white shadow-xl ring-0 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 bg-gradient-3"
              placeholder={`Email`}
              onChange={(e) => handleInputChange('email', e.target.value)}
              value={contactForm.email.value}
            />
          </div>
          <div className="mt-1 w-full">
            <input
              type="tel"
              name="mobile"
              className="block w-full rounded-xl border-0 p-4 text-white shadow-xl ring-0 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 bg-gradient-3"
              placeholder="Mobile Number"
              required={true}
              onChange={(e) => handleInputChange('mobile', e.target.value)}
              value={contactForm.mobile.value}
              minLength={10}
              maxLength={10}
            />
          </div>
          <div className="mt-1 w-full">
            <select
              name="industry"
              className="block w-full rounded-xl border-0 p-4 text-white shadow-sm ring-0 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 bg-gradient-3 appearance-auto"
              placeholder="Select Industry"
              onChange={(e) => handleInputChange('industry', e.target.value)}
              required
              value={selected}
            >
              {renderIndustryOptions()}
            </select>
          </div>
          <div className="pt-14 pb-8 grid place-items-center">
            <GradientBtn
              text="Send"
              size="lg"
              isSubmitButton
              type={'transparent'}
              isButtonDisable={isButtonDisable}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
