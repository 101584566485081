import { ParallaxScroll } from '../../animations/ParallaxScroll'
import { Typography } from '../../components'
import { KaryahKeyFeaturesCards } from '../../constants/AppConstants'
import { KeyFeaturesTitle, ParallaxBgMobile, SimpleImg } from '../../images'
import ContentWrapper from '../Common/ContentWrapper'
import KeyFeaturesFlowChart from './KeyFeatureFlowChart'
import { useScroll, useTransform, motion } from 'framer-motion'

const KeyFeatures = () => {
  const { scrollYProgress } = useScroll()
  const translateOdd = useTransform(scrollYProgress, [0, 1], [200, -600])
  return (
    <div className="bg-black">
      <img
        src={SimpleImg}
        alt="simple&seamless"
        className="-ml-28 py-20 max-sm:-ml-10"
      />
      <ParallaxScroll
        cards={KaryahKeyFeaturesCards}
        className={'pb-40 hidden md:grid'}
      />
      <ContentWrapper
        className="md:hidden h-[44rem] mb-16 grid place-items-center -mt-20"
        backgroundImage={ParallaxBgMobile}
      >
        <motion.div
          className="border-gradient-diagonal rounded-2xl h-[10rem] w-2/3 p-[1px] "
          style={{ y: translateOdd }}
        >
          <div className="grid place-items-center grid-flow-row-dense gap-0 backgroundGradient rounded-2xl">
            <img src={KeyFeaturesTitle} alt="title" className="h-12 pt-4" />
            <Typography
              size={'3xl'}
              type={'semi'}
              className="text-center pb-2 -pt-4"
            >
              Easy to use Feather light Features.
            </Typography>
          </div>
        </motion.div>
      </ContentWrapper>
      <KeyFeaturesFlowChart />
    </div>
  )
}

export default KeyFeatures
