import { ContainerScroll } from '../../animations/ContainerScroll'
import { DashboardImg, MainHeroBg4 } from '../../images'

const Dashboard = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${MainHeroBg4})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
      }}
      className="bg-cover"
    >
      <ContainerScroll
        child={
          <img
            src={DashboardImg}
            alt="dashboard-img"
            className="w-full h-auto hidden md:block"
          />
        }
      ></ContainerScroll>
      <img
        src={DashboardImg}
        alt="dashboard"
        className="w-full h-auto drop-shadow-xl md:hidden mb-20 -mt-16 shadow-2xl px-[10%] md:py-9"
      />
    </div>
  )
}

export default Dashboard
