import { Typography } from '../components'
import { KeyFeaturesTitle } from '../images'

export const KaryahKeyFeaturesTitle = () => {
  return (
    <div className="lg:ml-12 ml-2 w-full">
      <img src={KeyFeaturesTitle} alt="title" className={''} />
      <Typography
        size={'4xl'}
        type={'semi'}
        className=" text-white hidden lg:block"
      >
        {'Easy to use'}
      </Typography>
      <Typography
        size={'4xl'}
        type={'semi'}
        className=" text-white hidden lg:block"
      >
        {'Feather light features.'}
      </Typography>
      <Typography size={'xl'} type={'semi'} className=" text-white lg:hidden">
        {'Easy to use'}
      </Typography>
      <Typography size={'xl'} type={'semi'} className=" text-white lg:hidden">
        {'Feather light features.'}
      </Typography>
    </div>
  )
}
