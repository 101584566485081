import ContentWrapper from '../Common/ContentWrapper'
import { Typography } from '../../components'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const PrivacyPolicy = () => {
  const { state } = useLocation()
  const { targetId } = state || {}

  useEffect(() => {
    const el = document.getElementById(targetId)
    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        250
      )
    } else {
      setTimeout(() => {
        if (typeof window != 'undefined') {
          window.scrollTo({ top: (0, 0), behavior: 'smooth', alignToTop: true })
        }
      }, 200)
    }
  }, [targetId])

  return (
    <ContentWrapper className="pt-40 px-[10%] md:py-18 ">
      <Typography size={'2xl'} type={'bold'} className="pb-6">
        {'Privacy Policy'}
      </Typography>
      <ContentWrapper className="pt-8 px-[8%] md:py-9 bg-[#2b344040]/25">
        <Typography
          size={'sm'}
          type={'light'}
          className={`leading-normal line-clamp-12 max-h-[400px] md:max-h-[400px] overflow-scroll `}
        >
          Welcome to Karyamsha Labs Pvt Ltd ('Company,' 'we,' 'our,' 'us'). We
          are committed to protecting your privacy and ensuring a secure
          experience while using our software Products and/or Services
          ('Products and/or Services'). This Privacy Policy explains how we
          collect, use, and safeguard your information.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Information We Collect
          </Typography>
          <ol
            style={{
              listStyleType: 'disc',
              paddingLeft: 18,
              display: 'inline-block',
            }}
          >
            <li>
              <strong>Personal Information: </strong>When you create an account
              or use our Services, we may collect personal information such as
              your name, email address, phone number, and billing details.
            </li>
            <li>
              <strong>Usage Data: </strong>We collect information about how you
              interact with our Services, including IP address, browser type,
              and usage patterns.
            </li>
            <li>
              <strong>Technical Data: </strong>Information about your device,
              operating system, and application version.
            </li>
          </ol>
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            How We Use Your Information
          </Typography>
          <ol
            style={{
              listStyleType: 'disc',
              paddingLeft: 18,
              display: 'inline-block',
            }}
          >
            <li>
              <strong>To Provide and Improve Services: </strong>We use your
              information to deliver and enhance our Services, including
              customer support, billing, and performance monitoring.
            </li>
            <li>
              <strong>For Communication: </strong>We may use your contact
              details to send updates, promotions, and important notices related
              to our Services.
            </li>
            <li>
              <strong>For Security: </strong>To protect against unauthorized
              access and ensure the safety and integrity of our Services.
            </li>
          </ol>
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Sharing Your Information
          </Typography>
          <ol
            style={{
              listStyleType: 'disc',
              paddingLeft: 18,
              display: 'inline-block',
            }}
          >
            <li>
              <strong>With Service Providers: </strong>We may share your
              information with third-party service providers who perform
              Products and/or Services on our behalf, such as payment processing
              and customer support.
            </li>
            <li>
              <strong>For Legal Reasons: </strong>We may disclose your
              information if required by law or to protect our rights, property,
              or safety.
            </li>
          </ol>
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Data Security
          </Typography>
          We implement reasonable measures to safeguard your information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no method of transmission over the Internet or electronic storage is
          100% secure.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Your Choices
          </Typography>
          <ol
            style={{
              listStyleType: 'disc',
              paddingLeft: 18,
              display: 'inline-block',
            }}
          >
            <li>
              <strong>Access and Update: </strong>You can access and update your
              personal information through your account settings or by
              contacting us.
            </li>
            <li>
              <strong>Opt-Out: </strong>You can opt-out of receiving promotional
              communications from us by following the unsubscribe instructions
              in those communications.
            </li>
          </ol>
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Cookies and Tracking Technologies
          </Typography>
          We use cookies and similar technologies in some of our Products and/or
          Services to enhance your experience, analyze usage, and track
          performance. You can manage your cookie preferences through your
          browser settings.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Third-Party Links
          </Typography>
          Some of our Products and/or Services may contain links to third-party
          websites or services. We are not responsible for their privacy
          practices or content. We encourage you to review their privacy
          policies.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Children's Privacy
          </Typography>
          Our Products and/or Services are not intended for children under the
          age of 13. We do not knowingly collect personal information from
          children. If we become aware of such information, we will take steps
          to delete it.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Changes to This Privacy Policy
          </Typography>
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the updated policy on our
          website. Continued use of our Products and/or Services after changes
          constitutes acceptance of the revised policy.
          <br />
          <br />
          <br />
          <Typography size={'md'} type={'regular'}>
            Contact Us
          </Typography>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
          <br />
          <br />
          Karyamsha Labs Pvt Ltd
          <br />
          D60, New Dawn Villas, Rayasandra main road, gattahalli,
          <br />
          Bangalore, Karnataka - 560099
          <br />
          <br />
          Email: admin@karyah.app
        </Typography>
      </ContentWrapper>
    </ContentWrapper>
  )
}

export default PrivacyPolicy
