import './index.css'
import HomePage from './pages/HomePage'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import MainLayout from './pages/Common/MainLayout'
import { useState } from 'react'
import About from './pages/About'

const App = () => {
  const [showPopupForm, setShowPopupForm] = useState(false)
  const [whichPopupForm, setWhichPopupForm] = useState('contact')
  const [toast, setToast] = useState({ isActive: false, type: '', msg: '' })


  const handlePopupForm = (name: string) => {
    setWhichPopupForm(name)
    setShowPopupForm(true)
  }

  const MainLayoutProps = {
    handlePopupForm,
    whichPopupForm,
    showPopupForm,
    setShowPopupForm,
    toast,
    setToast,
  }

  return (
    <BrowserRouter>
      <div className="h-full backgroundGradient">
        <Routes>
          <Route>
            <Route
              path="/"
              element={
                <MainLayout  {...MainLayoutProps}>
                  <HomePage handlePopupForm={handlePopupForm}
                      setToast={setToast} />
                </MainLayout>
              }
            />
             <Route
              path="/about"
              element={
                <MainLayout  {...MainLayoutProps}>
                  <About />
                </MainLayout>
              }
            />
            <Route
              path="*"
              element={
                <MainLayout  {...MainLayoutProps}>
                  <Navigate to="/" />
                </MainLayout>
              }
            />
          </Route>
            <Route
              path="/terms-and-conditions"
              element={
                <MainLayout  {...MainLayoutProps}>
                  <TermsAndConditions />
                </MainLayout>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <MainLayout  {...MainLayoutProps}>
                  <PrivacyPolicy />
                </MainLayout>
              }
            />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
