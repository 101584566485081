const Divider = (props: { width: string; align?: string }) => {
  return (
    <div
      className={`flex ${
        props.align ? props.align : 'justify-center'
      } md:py-8 py-4`}
    >
      <div
        className={`bg-gradient-to-r border-gradient ${props.width} h-1 text-center align-middle rounded-full`}
      ></div>
    </div>
  )
}
export default Divider
