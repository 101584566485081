import { Typography, Accordion } from "../../components"
import { KaryahFounders } from "../../constants/AppConstants"
import { BulletGreenTick } from "../../images"

const AboutCards = () => {
    return (<div className="grid md:grid-cols-2 grid-cols-1 md:place-items-center md:pb-48 pb-28 px-[10%] text-white">
         <div className="max-sm:w-full w-2/3">
         <Typography
            size={'4xl'}
            type={'light'}
            className="text-left text-pretty lg:block hidden text-white leading-18 pt-20"
          >
            Our Karyah duo brings together some of the top talents from
        </Typography>
        <Typography
          size={'4xl'}
          type={'semi'}
          className="text-left text-pretty lg:block hidden py-2"
          isGradientText
        >
          Best of Both Worlds!
        </Typography>
        <Typography
            size={'lg'}
            type={'light'}
            className="text-center text-pretty lg:hidden text-white leading-18 pt-20"
          >
            Our Karyah duo brings together some of the top talents from
        </Typography>
        <Typography
          size={'lg'}
          type={'semi'}
          className="text-center text-pretty lg:hidden py-2 pb-10"
          isGradientText
        >
          Best of Both Worlds!
        </Typography>
         </div>
         <div className="container grid grid-cols-2 place-items-center gap-8 md:pt-8 pt-18 hidden md:grid m-auto">
            {KaryahFounders.map((item) => {
                return (
                <div
                    key={item.id}
                    className="row-span-1 flex flex-col rounded-xl p-[1px] border-gradient-diagonal h-full m-auto hover:-translate-y-3 justify-center"
                >
                    <div className="p-8 backgroundGradient rounded-xl h-full grid grid-rows-8 place-items-center">
                    <img src={item.img} alt={item.img} className='row-span-1 py-4' />
                    <Typography size={'2xl'} type={'semi'} className="pt-4 row-span-1 text-center m-auto" isGradientText>
                        {item.experience}
                    </Typography>
                    <Typography size={'sm'} type={'light'} className="row-span-1 text-center pb-8">
                        {item.expertise}
                    </Typography>
                    <div className='row-span-2'>
                        <Typography size={'xs'} type={'regular'} className='py-4'>
                        Expert in
                        </Typography>
                        <div className="space-y-1 py-4">
                        
                        {item.usecases.map((item) => {
                            return (
                            <div className='flex justify-start' key={item}>
                                <img src={BulletGreenTick} alt="" className='pr-2' />
                                <Typography size={'xs'} type={'regular'} key={item} className='py-2'>
                                {item}
                            </Typography>
                            </div>
                            )
                        })}
                        </div>
                    </div>
                    <div className="pb-8">
                    <Typography size={'sm'} type={'semi'} className="row-span-1 text-center pt-8">
                        {item.footerTitle}
                    </Typography>
                    {item.footerDesc.map((fitem)=> {
                        return(<><Typography size={'sm'} type={'light'} className="row-span-1 text-center" key={fitem}>
                        {fitem}
                        </Typography>
                        </>)
                    })}
                    </div>
                    </div>
                </div>
                )
            })}
        </div>
        <div className="container grid grid-cols-1 place-items-center gap-8 pt-8 md:hidden block">
        {KaryahFounders.map((item) => {
            return (
            <div key={item}><Accordion
            img={item.img}
            desc={item.experience}
            desc2={item.expertise}
            usecases={item.usecases}
            isCardOpen={true}
            showBtn={false}
            isGradientDesc={true}
            showDivider={false}
            isCenterText = {true}
            usecasesTitle={'Expert in'}
            footerTitle={item.footerTitle}
            footerDesc={item.footerDesc}
            />
            </div>
            )
        })}
        </div>
    </div>)
}

export default AboutCards