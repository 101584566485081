/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'react-router-dom'
import { CloseMark, Menubar } from '../../../images'
import ContentWrapper from '../ContentWrapper'
import { Typography, GradientBtn } from '../../../components'
import { navbarList } from '../../../constants/AppConstants'
import { useEffect, useState } from 'react'

interface IMobileMenuProps {
  handlePopupForm: any
}

export const MobileMenu = (props: IMobileMenuProps) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  useEffect(() => {
    if (isOpenMenu) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [isOpenMenu])

  return (
    <>
      <img
        src={Menubar}
        alt="menu"
        onClick={() => setIsOpenMenu(true)}
        className="absolute right-6"
      />

      {isOpenMenu && (
        <ContentWrapper className="backgroundGradient flex flex-col items-center justify-items-between overflow-y-scroll bg-opacity-90 w-full absolute left-0 top-0 backdrop-blur-xl h-screen py-4 scroll-auto">
          <div>
            <div className="absolute right-6">
              <img
                className="w-12 h-12 m-0 p-0"
                src={CloseMark}
                alt="close"
                onClick={() => setIsOpenMenu(false)}
              />
            </div>
            <div className="border-opacity-60 pt-14">
              {navbarList.map((eachMenu) => (
                <Link
                  key={eachMenu.link}
                  to={`${eachMenu.link}`}
                  className="flex items-center justify-center text-center cursor-pointer py-1"
                  onClick={() => setIsOpenMenu(false)}
                >
                  <Typography
                    size={'lg'}
                    type={'regular'}
                    className="text-opacity-60 pb-2 leading-10"
                    key={eachMenu.link}
                  >
                    {eachMenu.name}
                  </Typography>
                </Link>
              ))}
              <GradientBtn
                text="Get in Touch"
                size="md"
                onClick={() => {
                  props.handlePopupForm && props.handlePopupForm('contact')
                  setIsOpenMenu(false)
                }}
                type={'glow'}
                className=" px-auto mt-20"
              />
            </div>
          </div>
        </ContentWrapper>
      )}
    </>
  )
}

export default MobileMenu
