// Core component that receives mouse positions and renders pointer and content

import React, { useEffect, useState } from 'react'

import { motion, AnimatePresence, useMotionValue } from 'framer-motion'
import { cn } from '../../../utils/cn'

export const FollowerPointerCard = ({
  children,
  className,
  title,
}: {
  children: React.ReactNode
  className?: string
  title?: string | React.ReactNode
}) => {
  const x = useMotionValue(0)
  const y = useMotionValue(0)
  const ref = React.useRef<HTMLDivElement>(null)
  const [rect, setRect] = useState<DOMRect | null>(null)
  const [isInside, setIsInside] = useState<boolean>(false) // Add this line

  useEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect())
    }
  }, [])

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (rect) {
      const scrollX = window.scrollX
      const scrollY = window.scrollY
      x.set(e.clientX - rect.left + scrollX)
      y.set(e.clientY - rect.top + scrollY)
    }
  }
  const handleMouseLeave = () => {
    setIsInside(false)
  }

  const handleMouseEnter = () => {
    setIsInside(true)
  }
  return (
    <div
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      style={{
        cursor: 'none',
      }}
      ref={ref}
      className={cn('relative', className)}
    >
      <AnimatePresence mode="wait">
        {isInside && <FollowPointer x={x} y={y} title={title} />}
      </AnimatePresence>
      {children}
    </div>
  )
}

export const FollowPointer = ({
  x,
  y,
  title,
}: {
  x: any
  y: any
  title?: string | React.ReactNode
}) => {
  return (
    <motion.div
      className="h-3 w-3 rounded-tr-lg rounded-b-lg absolute z-50"
      style={{
        top: y,
        left: x,
        pointerEvents: 'none',
      }}
      initial={{
        scale: 1,
        opacity: 1,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0,
        opacity: 0,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 31 30"
        stroke="#67B6FF"
        fill="#67B6FF"
        className=" transform -translate-x-[18px] -translate-y-[1px]"
      >
        <path
          d="M0 0L11.2239 29.4627L16.8358 15.4328L30.8657 10.5224L0 0Z"
          fill="#67B6FF"
        />
      </svg>

      <motion.div
        initial={{
          scale: 0.5,
          opacity: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
        }}
        exit={{
          scale: 0.5,
          opacity: 0,
        }}
        className={
          'px-2 py-1 text-white bg-[#67B6FF] whitespace-nowrap min-w-max text-xs rounded-tr-full rounded-b-full'
        }
      >
        {title || `You`}
      </motion.div>
    </motion.div>
  )
}
