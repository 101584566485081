/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Typography from '../Typography'

interface IGradientBtn {
  onClick?: () => void
  text: string
  size: string
  className?: string
  type?: string
  isSubmitButton?: boolean
  isButtonDisable?: boolean
}

const GradientBtn = (props: IGradientBtn) => {
  const {
    text,
    onClick,
    size,
    className,
    type,
    isSubmitButton = false,
    isButtonDisable = false,
  } = props

  const getSizeVariant = () => {
    let clsName = ''
    switch (size) {
      case 'sm':
        clsName = 'px-6 h-6'
        break
      case 'md':
        clsName = 'px-6 h-10'
        break
      case 'lg':
        clsName = 'px-12 h-10'
        break
      case 'xl':
        clsName = 'px-14 h-12'
        break
      case 'xxl':
        clsName = 'w-72 h-14'
        break
      default:
        clsName = 'px-8 h-8'
        break
    }
    return clsName
  }

  const getTypeVariant = () => {
    let clsName = ''
    switch (type) {
      case 'fill':
        clsName = ''
        break
      case 'transparent':
        clsName = `bg-black/80 hover:bg-transparent`
        break
      case 'glow':
        clsName = 'bg-gradient-glow hover:bg-black/80'
        break
      default:
        clsName = ''
        break
    }
    return clsName
  }

  const handleButtonClick = () => {
    if (!isButtonDisable) {
      onClick && onClick()
    }
  }

  return (
    <div className={`items-center justify-center cursor-pointer`}>
      <div
        className={`${className} rounded-full bg-gradient-to-r border-gradient border-gradient-btn p-[1px] ${getTypeVariant()}`}
      >
        <div className={`rounded-full ${getTypeVariant()}`}>
          <button
            className={` flex rounded-full items-center justify-center ${getTypeVariant()} ${getSizeVariant()}`}
            disabled={isButtonDisable}
            onClick={() => (onClick ? handleButtonClick() : {})}
            type={isSubmitButton ? 'submit' : 'button'}
          >
            <Typography
              size={`${size == 'md' ? 'sm' : 'xs'}`}
              type={'semi'}
              className="text-white"
            >
              {text}
            </Typography>
          </button>
        </div>
      </div>
    </div>
  )
}

export default GradientBtn
