import { Fragment, ReactChild, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button from '../Button'
import Typography from '../Typography'

interface IPopup {
  isOpen: boolean
  setIsOpen: any
  child: ReactChild
  title: string
  width: string
}

const Popup = (props: IPopup) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 max-sm:w-full shadow-xl"
        initialFocus={cancelButtonRef}
        onClose={() => props.setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 max-sm:p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden p-[1px] border-gradient-diagonal rounded-xl text-left shadow-xl transition-all sm:my-8 max-sm:w-full ${props.width}`}
              >
                <div className="backgroundGradient rounded-xl px-4 pb-4 pt-10 sm:p-6 sm:pb-4 w-full">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-1 text-left w-full  px-8 py-2">
                      <Dialog.Title
                        as="h1"
                        className="text-xl font-semibold leading-6 text-white"
                      >
                        {props.title}
                        <Button
                          size={'xs'}
                          background={'fill-gray'}
                          text="Close"
                          className="shadow-xl absolute right-4 bg-gradient-3"
                          isSubmitButton={false}
                          isButtonDisable={false}
                          onClick={() => props.setIsOpen(false)}
                          ref={cancelButtonRef}
                        >
                          <Typography
                            size="xs"
                            type="light"
                            className="text-white"
                          >
                            Close
                          </Typography>
                        </Button>
                        {/* <button
                          type="button"
                          className="px-2 py-1 text-xs rounded-full shadow-xl ring-0 sm:mt-0 sm:w-auto absolute border-0 right-10 focus:ring-0 focus:border-0 bg-gradient-3 font-light"
                          onClick={() => props.setIsOpen(false)}
                          ref={cancelButtonRef}
                        >
                          Close
                        </button> */}
                      </Dialog.Title>
                      <div className="w-full mt-5 mb-6">{props.child}</div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Popup
