import ContentWrapper from '../Common/ContentWrapper'
import { Typography, Divider } from '../../components'
import { KeyFeaturesFlow, KeyFeaturesFlowMobile } from '../../images'

const KeyFeaturesFlowChart = () => {
  return (
    <>
      <ContentWrapper
        backgroundImage={KeyFeaturesFlow}
        className="h-[100rem] relative hidden md:block"
      >
        <div className="grid grid-cols-1 w-60 absolute top-[40rem] lg:left-[18rem] left-[2%]">
          <Typography size="3xl" type="light" className={'pb-1 text-right'}>
            Hands-on Industry Experts at work
          </Typography>
          <Divider width="w-1/2" align={'justify-end'} />
        </div>
        <div className="grid grid-cols-1 w-60 absolute top-[48rem] lg:right-[18rem] right-[2%]">
          <Typography size="3xl" type="light" className={'pb-1 text-left'}>
            User-Centered Design and Architecture
          </Typography>
          <Divider width="w-1/2" align={'justify-start'} />
        </div>
        <div className="grid grid-cols-1 w-60 absolute top-[76rem] lg:left-[18rem] left-[2%]">
          <Typography size="3xl" type="light" className={'pb-1 text-right'}>
            Business Driven Scalable SaaS
          </Typography>
          <Divider width="w-1/2" align={'justify-end'} />
        </div>
        <div className="grid grid-cols-1 w-60 absolute top-[90rem] lg:right-[18rem] right-[2%]">
          <Typography size="3xl" type="light" className={'pb-1 justify-end'}>
            Highly reliable Support System
          </Typography>
          <Divider width="w-1/2" align={'text-right'} />
        </div>
      </ContentWrapper>
      <ContentWrapper className="md:hidden grid grid-cols-1 gap-8 place-items-center pb-10">
        <img src={KeyFeaturesFlowMobile} alt="key features" />
        <div className="grid grid-cols-2 gap-2 px-4 mt-10">
          <div className="grid grid-cols-1 p-2">
            <Typography size="lg" type="light" className={'pb-1 text-left'}>
              Hands-on Industry Experts at work
            </Typography>
            <Divider width="w-1/2" align={'justify-start'} />
          </div>
          <div className="grid grid-cols-1 p-2">
            <Typography size="lg" type="light" className={'pb-1 text-left'}>
              User-Centered Design and Architecture
            </Typography>
            <Divider width="w-1/2" align={'justify-start'} />
          </div>
          <div className="grid grid-cols-1 p-2">
            <Typography size="lg" type="light" className={'pb-1 text-left'}>
              Business Driven Scalable SaaS
            </Typography>
            <Divider width="w-1/2" align={'justify-start'} />
          </div>
          <div className="grid grid-cols-1 p-2">
            <Typography size="lg" type="light" className={'pb-1 justify-end'}>
              Highly reliable Support System
            </Typography>
            <Divider width="w-1/2" align={'text-right'} />
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}

export default KeyFeaturesFlowChart
