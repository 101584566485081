interface IContentWrapper {
  children?: any
  className: string
  backgroundImage?: string
}

const ContentWrapper = (props: IContentWrapper) => {
  const { children, className, backgroundImage } = props
  return (
    <div
      className={`text-white ${className}`}
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
      }}
    >
      {children}
    </div>
  )
}

export default ContentWrapper
