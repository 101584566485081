import { Typography } from '../../components'
import {
  KaryahLogo,
  MainHeroBg1,
  AboutHeroImg,
  DesignCursor,
  DevCursor,
} from '../../images'
import { FollowerPointerCard } from '../../animations/FollowerPointerCard'
const TitleComponent = ({ title }: { title: string }) => (
  <div className="flex space-x-2 items-center bg-[#67B6FF] px-3">
    <p>{title}</p>
  </div>
)

const AboutHero = () => {
  return (
    <FollowerPointerCard title={<TitleComponent title={'You'} />}>
      <div
        className=""
        style={{
          backgroundImage: `url(${MainHeroBg1})`,
          backgroundRepeat: 'repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="grid grid-cols-2 place-items-center md:py-48 max-sm:pt-28 px-[10%]">
          <div className="max-sm:w-full">
            <img src={AboutHeroImg} alt="hero" />
          </div>
          <div className="max-sm:w-full w-2/3 max-sm:pl-4">
            <div className="md:pb-14 max-sm:pl-8">
              <img src={DesignCursor} alt="" className="pb-2 md:pl-52 pl-8" />
              <img src={KaryahLogo} alt="logo" className="pb-2 w-80" />
              <img src={DevCursor} alt="" className="pb-2 " />
            </div>

            <Typography
              size={'4xl'}
              type={'light'}
              className="text-left text-pretty lg:block hidden text-white leading-18"
            >
              With passion for entrepreneurship, complementing skillsets and
              expertise,
            </Typography>
            <Typography
              size={'4xl'}
              type={'semi'}
              className="text-left text-pretty lg:block hidden py-2"
              isGradientText
            >
              We Deliver Quality.
            </Typography>
            <Typography
              size={'sm'}
              type={'light'}
              className="text-left text-pretty lg:hidden text-white leading-18 pl-8"
            >
              With passion for entrepreneurship, complementing skillsets and
              expertise,
            </Typography>
            <Typography
              size={'sm'}
              type={'semi'}
              className="text-left text-pretty lg:hidden py-2  pl-8"
              isGradientText
            >
              We Deliver Quality.
            </Typography>
          </div>
        </div>
      </div>
    </FollowerPointerCard>
  )
}

export default AboutHero
